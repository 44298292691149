@media print {
  // Entferne Whitespace im Header
  header {
    padding: 0 !important;
    margin: 0 !important;
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
    .ba-overlay {
      padding: 0 !important;
      margin: 0 !important;
    }
  }

  // Da Margin im Header bereits auf 0 gesetzt wurde,
  // kann ba-pull-header deaktiviert werden
  // Greift nur, wenn ViewEncapsulation.None
  .ba-pull-header {
    margin: 0 !important;
  }
}
